import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode";

import { CredentialResponse } from "@react-oauth/google";

import SplashScreen from "components/SplashScreen";

import { User, AuthContextProps } from "./AuthProvider.types";

const publicPathnames = ["/", "/login"];

const AuthContext = createContext<AuthContextProps>({
  signIn: () => {},
  signOut: () => {}
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(JSON.parse(window.sessionStorage.getItem("user")!));
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user && !publicPathnames.includes(location.pathname)) {
      navigate("/login");
    } else if (user && publicPathnames.includes(location.pathname)) {
      navigate("/");
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    (credentialResponse: CredentialResponse) => {
      const storage = window.sessionStorage;

      const token = JSON.stringify(credentialResponse.credential);
      const user = jwtDecode(token) as User;

      const allowedUsers = process.env.REACT_APP_ADMIN_PRIVILEGES_LIST?.split(",");

      if (!allowedUsers?.includes(user?.email)) {
        setError("You are not allowed to access this application.");
        return;
      }

      setUser(user);
      storage.setItem("user", JSON.stringify(user));

      navigate("/dashboard");
    },
    [navigate]
  );

  const signOut = useCallback(() => {
    const storage = window.sessionStorage;

    storage.removeItem("user");

    setUser(undefined);
    navigate("/login");
  }, [navigate]);

  const value = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      error
    }),
    [user, signIn, signOut, error]
  );

  return <AuthContext.Provider value={value}>{loading ? <SplashScreen /> : children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export default AuthProvider;
