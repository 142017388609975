import { createBem } from "utils/style-utils";

import "./SplashScreen.scss";

const bem = createBem("common-SplashScreen");

const SplashScreen = () => (
  <div className={bem("")}>
    <img
      width={72}
      className={bem("animate-pulse")}
      src="/symphony-logo-no-fill.svg"
      alt="Symphony small purple logo"
    />
  </div>
);

export default SplashScreen;
