import { StrictMode, Suspense, lazy } from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import AuthProvider from "context/AuthProvider";

import SplashScreen from "components/SplashScreen";

import "./index.css";

const MagicTv = lazy(() => import("pages/MagicTv"));
const Login = lazy(() => import("pages/Login"));
const AdminDashboard = lazy(() => import("pages/AdminDashboard"));

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <Router>
        <AuthProvider>
          <Suspense fallback={<SplashScreen />}>
            <Routes>
              <Route path="/" element={<MagicTv />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  </StrictMode>
);
