/**
 * Creates a bem utility for generating bem class names. Usage:
 *
 * const bem = createBem("myBlock")
 *
 * // Block
 * bem() // "myBlock"
 *
 * // Element
 * bem("myElement") // "myBlock__myElement"
 *
 * // Modifiers
 * bem("", "myModifier") // "myBlock myBlock--myModifier"
 * bem("myElement", "myModifier") // "myBlock__myElement myBlock__myElement--myModifier"
 *
 * // Object Modifiers
 * let selected = undefined;
 * let active = true;
 * let count = 5;
 * // returns "myBlock myBlock--active myBlock--hasContent"
 * bem("", { selected, active, hasContent: count})
 *
 * // You can provide both String and Object modifiers and an Element in any order
 * let type = 'vertical';
 * // returns "myBlock__myEl  myBlock__myEl--vertical myBlock__myEl--active myBlock__myEl--hasContent"
 * bem("myEl", type, { selected, active, hasContent: count})
 */
type Modifier = string | { [modifier: string]: unknown };

type Bem = (element?: string, ...modifiers: Modifier[]) => string;

export const createBem =
  (block: string): Bem =>
  (element, ...modifiers) => {
    let result = block;

    if (element) {
      result = `${result}__${element}`;
    }
    const context = result;
    for (const modifier of modifiers) {
      if (typeof modifier === "string") {
        result += ` ${context}--${modifier}`;
      } else if (modifier) {
        for (const [modifierKey, active] of Object.entries(modifier)) {
          if (active) {
            result += ` ${context}--${modifierKey}`;
          }
        }
      }
    }
    return result;
  };
